var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { useResponsive } from 'src/hooks/use-responsive';
import { bgBlur } from 'src/theme/css';
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import { NAV } from '../config-layout';
// ----------------------------------------------------------------------
export default function NavToggleButton(_a) {
    var { sx } = _a, other = __rest(_a, ["sx"]);
    const theme = useTheme();
    const settings = useSettingsContext();
    const lgUp = useResponsive('up', 'lg');
    if (!lgUp) {
        return null;
    }
    return (_jsx(IconButton, Object.assign({ size: "small", onClick: () => settings.onUpdate('themeLayout', settings.themeLayout === 'vertical' ? 'mini' : 'vertical'), sx: Object.assign(Object.assign(Object.assign({ p: 0.5, top: 32, position: 'fixed', left: NAV.W_VERTICAL - 12, zIndex: theme.zIndex.appBar + 1, border: `dashed 1px ${theme.palette.divider}` }, bgBlur({ opacity: 0.48, color: theme.palette.background.default })), { '&:hover': {
                bgcolor: 'background.default',
            } }), sx) }, other, { children: _jsx(Iconify, { width: 16, icon: settings.themeLayout === 'vertical'
                ? 'eva:arrow-ios-back-fill'
                : 'eva:arrow-ios-forward-fill' }) })));
}
