import { jsx as _jsx } from "react/jsx-runtime";
import { m } from 'framer-motion';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Badge, { badgeClasses } from '@mui/material/Badge';
import Iconify from 'src/components/iconify';
import { varHover } from 'src/components/animate';
import { useSettingsContext } from 'src/components/settings';
export default function SettingsButton({ sx }) {
    const settings = useSettingsContext();
    return (_jsx(Badge, { color: "error", variant: "dot", invisible: !settings.canReset, sx: Object.assign({ [`& .${badgeClasses.badge}`]: {
                top: 8,
                right: 8,
            } }, sx), children: _jsx(Box, { component: m.div, animate: {
                rotate: [0, settings.open ? 0 : 360],
            }, transition: {
                duration: 12,
                ease: 'linear',
                repeat: Infinity,
            }, children: _jsx(IconButton, { component: m.button, whileTap: "tap", whileHover: "hover", variants: varHover(1.05), "aria-label": "settings", onClick: settings.onToggle, sx: {
                    width: 40,
                    height: 40,
                }, children: _jsx(Iconify, { icon: "solar:settings-bold-duotone", width: 24 }) }) }) }));
}
