import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useCallback } from 'react';
import Collapse from '@mui/material/Collapse';
import { usePathname } from 'src/routes/hooks';
import { useActiveLink } from 'src/routes/hooks/use-active-link';
import NavItem from './nav-item';
// ----------------------------------------------------------------------
export default function NavList({ data, depth, slotProps }) {
    const pathname = usePathname();
    const active = useActiveLink(data.path, !!data.children);
    const [openMenu, setOpenMenu] = useState(active);
    useEffect(() => {
        if (!active) {
            handleCloseMenu();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);
    const handleToggleMenu = useCallback(() => {
        if (data.children) {
            setOpenMenu((prev) => !prev);
        }
    }, [data.children]);
    const handleCloseMenu = useCallback(() => {
        setOpenMenu(false);
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(NavItem, { open: openMenu, onClick: handleToggleMenu, 
                //
                title: data.title, path: data.path, icon: data.icon, info: data.info, roles: data.roles, caption: data.caption, disabled: data.disabled, 
                //
                depth: depth, hasChild: !!data.children, externalLink: data.path.includes('http'), currentRole: slotProps === null || slotProps === void 0 ? void 0 : slotProps.currentRole, 
                //
                active: active, className: active ? 'active' : '', sx: Object.assign({ mb: `${slotProps === null || slotProps === void 0 ? void 0 : slotProps.gap}px` }, (depth === 1 ? slotProps === null || slotProps === void 0 ? void 0 : slotProps.rootItem : slotProps === null || slotProps === void 0 ? void 0 : slotProps.subItem)) }), !!data.children && (_jsx(Collapse, { in: openMenu, unmountOnExit: true, children: _jsx(NavSubList, { data: data.children, depth: depth, slotProps: slotProps }) }))] }));
}
// ----------------------------------------------------------------------
function NavSubList({ data, depth, slotProps }) {
    return (_jsx(_Fragment, { children: data.map((list) => (_jsx(NavList, { data: list, depth: depth + 1, slotProps: slotProps }, list.title))) }));
}
