import { jsx as _jsx } from "react/jsx-runtime";
import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { GuestGuard } from 'src/auth/guard';
import AuthClassicLayout from 'src/layouts/auth/classic';
import { SplashScreen } from 'src/components/loading-screen';
// ----------------------------------------------------------------------
// JWT
const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));
const JwtRegisterPage = lazy(() => import('src/pages/auth/jwt/register'));
// ----------------------------------------------------------------------
const authJwt = {
    path: 'jwt',
    element: (_jsx(Suspense, { fallback: _jsx(SplashScreen, {}), children: _jsx(Outlet, {}) })),
    children: [
        {
            path: 'login',
            element: (_jsx(GuestGuard, { children: _jsx(AuthClassicLayout, { children: _jsx(JwtLoginPage, {}) }) })),
        },
        {
            path: 'register',
            element: (_jsx(GuestGuard, { children: _jsx(AuthClassicLayout, { title: "Manage the job more effectively with Minimal", children: _jsx(JwtRegisterPage, {}) }) })),
        },
    ],
};
export const authRoutes = [
    {
        path: 'auth',
        children: [authJwt],
    },
];
