var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import ListItemButton from '@mui/material/ListItemButton';
import { RouterLink } from 'src/routes/components';
import Iconify from '../../iconify';
// ----------------------------------------------------------------------
const NavItem = forwardRef((_a, ref) => {
    var { title, path, icon, info, disabled, caption, roles, 
    //
    open, depth, active, hasChild, externalLink, currentRole = 'admin' } = _a, other = __rest(_a, ["title", "path", "icon", "info", "disabled", "caption", "roles", "open", "depth", "active", "hasChild", "externalLink", "currentRole"]);
    const subItem = depth !== 1;
    const renderContent = (_jsxs(StyledNavItem, Object.assign({ ref: ref, open: open, depth: depth, active: active, disabled: disabled }, other, { children: [icon && (_jsx(Box, { component: "span", className: "icon", children: icon })), title && (_jsx(Box, { component: "span", className: "label", children: title })), caption && (_jsx(Tooltip, { title: caption, arrow: true, children: _jsx(Iconify, { width: 16, icon: "eva:info-outline", className: "caption" }) })), info && (_jsx(Box, { component: "span", className: "info", children: info })), hasChild && (_jsx(Iconify, { width: 16, className: "arrow", icon: subItem ? 'eva:arrow-ios-forward-fill' : 'eva:arrow-ios-downward-fill' }))] })));
    // Hidden item by role
    if (roles && !roles.includes(`${currentRole}`)) {
        return null;
    }
    if (externalLink)
        return (_jsx(Link, { href: path, target: "_blank", rel: "noopener", color: "inherit", underline: "none", sx: Object.assign({}, (disabled && {
                cursor: 'default',
            })), children: renderContent }));
    return (_jsx(Link, { component: RouterLink, href: path, color: "inherit", underline: "none", sx: Object.assign({}, (disabled && {
            cursor: 'default',
        })), children: renderContent }));
});
export default NavItem;
// ----------------------------------------------------------------------
const StyledNavItem = styled(ListItemButton, {
    shouldForwardProp: (prop) => prop !== 'active',
})(({ active, open, depth, theme }) => {
    const subItem = depth !== 1;
    const opened = open && !active;
    const baseStyles = {
        item: Object.assign(Object.assign({}, theme.typography.body2), { borderRadius: 6, color: theme.palette.text.secondary, fontWeight: theme.typography.fontWeightMedium }),
        icon: {
            width: 22,
            height: 22,
            flexShrink: 0,
            marginRight: theme.spacing(1),
        },
        label: {
            textTransform: 'capitalize',
        },
        caption: {
            marginLeft: theme.spacing(0.75),
            color: theme.palette.text.disabled,
        },
        info: {
            display: 'inline-flex',
            marginLeft: theme.spacing(0.75),
        },
        arrow: {
            marginLeft: theme.spacing(0.75),
        },
    };
    return Object.assign(Object.assign({}, (!subItem && Object.assign(Object.assign(Object.assign(Object.assign({}, baseStyles.item), { minHeight: 32, flexShrink: 0, padding: theme.spacing(0, 0.75), '& .icon': Object.assign({}, baseStyles.icon), '& .label': Object.assign(Object.assign({}, baseStyles.label), { whiteSpace: 'nowrap' }), '& .caption': Object.assign({}, baseStyles.caption), '& .info': Object.assign({}, baseStyles.info), '& .arrow': Object.assign({}, baseStyles.arrow) }), (active && {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.action.selected,
        fontWeight: theme.typography.fontWeightSemiBold,
    })), (opened && {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.action.hover,
    })))), (subItem && Object.assign(Object.assign(Object.assign(Object.assign({}, baseStyles.item), { minHeight: 34, padding: theme.spacing(0, 1), '& .icon': Object.assign({}, baseStyles.icon), '& .label': Object.assign(Object.assign({}, baseStyles.label), { flexGrow: 1 }), '& .caption': Object.assign({}, baseStyles.caption), '& .info': Object.assign({}, baseStyles.info), '& .arrow': Object.assign(Object.assign({}, baseStyles.arrow), { marginRight: theme.spacing(-0.5) }) }), (active && {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.action.selected,
        fontWeight: theme.typography.fontWeightSemiBold,
    })), (opened && {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.action.hover,
    }))));
});
