import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { memo, useState, useCallback } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import InputAdornment from '@mui/material/InputAdornment';
import Dialog, { dialogClasses } from '@mui/material/Dialog';
import { useRouter } from 'src/routes/hooks';
import { useBoolean } from 'src/hooks/use-boolean';
import { useEventListener } from 'src/hooks/use-event-listener';
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import SearchNotFound from 'src/components/search-not-found';
import ResultItem from './result-item';
import { useNavData } from '../../dashboard/config-navigation';
import { applyFilter, groupedData, getAllItems } from './utils';
// ----------------------------------------------------------------------
function Searchbar() {
    const theme = useTheme();
    const router = useRouter();
    const search = useBoolean();
    const [searchQuery, setSearchQuery] = useState('');
    const navData = useNavData();
    const handleClose = useCallback(() => {
        search.onFalse();
        setSearchQuery('');
    }, [search]);
    const handleKeyDown = (event) => {
        if (event.key === 'k' && event.metaKey) {
            search.onToggle();
            setSearchQuery('');
        }
    };
    useEventListener('keydown', handleKeyDown);
    const handleClick = useCallback((path) => {
        if (path.includes('http')) {
            window.open(path);
        }
        else {
            router.push(path);
        }
        handleClose();
    }, [handleClose, router]);
    const handleSearch = useCallback((event) => {
        setSearchQuery(event.target.value);
    }, []);
    const dataFiltered = applyFilter({
        inputData: getAllItems({ data: navData }),
        query: searchQuery,
    });
    const notFound = searchQuery && !dataFiltered.length;
    const renderItems = () => {
        const data = groupedData(dataFiltered);
        return Object.keys(data)
            .sort((a, b) => -b.localeCompare(a))
            .map((group, index) => (_jsx(List, { disablePadding: true, children: data[group].map((item) => {
                const { title, path } = item;
                const partsTitle = parse(title, match(title, searchQuery));
                const partsPath = parse(path, match(path, searchQuery));
                return (_jsx(ResultItem, { path: partsPath, title: partsTitle, groupLabel: searchQuery && group, onClickItem: () => handleClick(path) }, `${title}${path}`));
            }) }, group || index)));
    };
    const renderButton = (_jsx(Stack, { direction: "row", alignItems: "center" }));
    return (_jsxs(_Fragment, { children: [renderButton, _jsxs(Dialog, { fullWidth: true, maxWidth: "sm", open: search.value, onClose: handleClose, transitionDuration: {
                    enter: theme.transitions.duration.shortest,
                    exit: 0,
                }, PaperProps: {
                    sx: {
                        mt: 15,
                        overflow: 'unset',
                    },
                }, sx: {
                    [`& .${dialogClasses.container}`]: {
                        alignItems: 'flex-start',
                    },
                }, children: [_jsx(Box, { sx: { p: 3, borderBottom: `solid 1px ${theme.palette.divider}` }, children: _jsx(InputBase, { fullWidth: true, autoFocus: true, placeholder: "Search...", value: searchQuery, onChange: handleSearch, startAdornment: _jsx(InputAdornment, { position: "start", children: _jsx(Iconify, { icon: "eva:search-fill", width: 24, sx: { color: 'text.disabled' } }) }), endAdornment: _jsx(Label, { sx: { letterSpacing: 1, color: 'text.secondary' }, children: "esc" }), inputProps: {
                                sx: { typography: 'h6' },
                            } }) }), _jsx(Scrollbar, { sx: { p: 3, pt: 2, height: 400 }, children: notFound ? _jsx(SearchNotFound, { query: searchQuery, sx: { py: 10 } }) : renderItems() })] })] }));
}
export default memo(Searchbar);
