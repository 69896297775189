import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable perfectionist/sort-imports */
// import './custom-process';
// import 'process'
import 'src/global.css';
import "@aws-amplify/ui-react/styles.css";
// ----------------------------------------------------------------------
import { useEffect } from "react";
import { Authenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import Router from 'src/routes/sections';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import ThemeProvider from 'src/theme';
import ProgressBar from 'src/components/progress-bar';
import { MotionLazy } from 'src/components/animate/motion-lazy';
import { SettingsDrawer, SettingsProvider } from 'src/components/settings';
import { AuthProvider } from 'src/auth/context/jwt';
import SnackbarProvider from 'src/components/snackbar/snackbar-provider';
import awsExports from './aws-exports';
Amplify.configure(awsExports);
// ----------------------------------------------------------------------
export default function App() {
    useEffect(() => {
        window.process = Object.assign(Object.assign({}, window.process), { nextTick: (callback, ...args) => new Promise((resolve) => {
                setTimeout(() => {
                    callback(...args);
                    resolve(null);
                }, 0);
            }) });
    });
    const charAt = `
     ██    
    ▓▓▓▓   
   ▒▒▒▒▒▒  
  ░░░░░░░░ 
  ██    ██ 
  `;
    console.info(`%c${charAt}`, 'color: #5BE49B');
    useScrollToTop();
    return (_jsx(Authenticator, { children: ({ signOut, user }) => (_jsx(AuthProvider, { signOut: signOut, user: user, children: _jsx(SettingsProvider, { defaultSettings: {
                    themeMode: 'light', // 'light' | 'dark'
                    themeDirection: 'ltr', //  'rtl' | 'ltr'
                    themeContrast: 'default', // 'default' | 'bold'
                    themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
                    themeColorPresets: 'orange', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
                    themeStretch: false,
                }, children: _jsx(ThemeProvider, { children: _jsx(MotionLazy, { children: _jsxs(SnackbarProvider, { children: [_jsx(SettingsDrawer, {}), _jsx(ProgressBar, {}), _jsx(Router, {})] }) }) }) }) })) }));
}
