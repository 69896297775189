import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import merge from 'lodash/merge';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { useSettingsContext } from 'src/components/settings';
// system
import { palette } from './palette';
import { shadows } from './shadows';
import { typography } from './typography';
// options
import RTL from './options/right-to-left';
import { customShadows } from './custom-shadows';
import { componentsOverrides } from './overrides';
import { createPresets } from './options/presets';
import { createContrast } from './options/contrast';
export default function ThemeProvider({ children }) {
    const settings = useSettingsContext();
    const presets = createPresets(settings.themeColorPresets);
    const contrast = createContrast(settings.themeContrast, settings.themeMode);
    const memoizedValue = useMemo(() => ({
        palette: Object.assign(Object.assign(Object.assign({}, palette(settings.themeMode)), presets.palette), contrast.palette),
        customShadows: Object.assign(Object.assign({}, customShadows(settings.themeMode)), presets.customShadows),
        direction: settings.themeDirection,
        shadows: shadows(settings.themeMode),
        shape: { borderRadius: 8 },
        typography,
    }), [
        settings.themeMode,
        settings.themeDirection,
        presets.palette,
        presets.customShadows,
        contrast.palette,
    ]);
    const theme = createTheme(memoizedValue);
    theme.components = merge(componentsOverrides(theme), contrast.components);
    return (_jsx(MuiThemeProvider, { theme: theme, children: _jsxs(RTL, { themeDirection: settings.themeDirection, children: [_jsx(CssBaseline, {}), children] }) }));
}
