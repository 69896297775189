import { alpha, styled } from '@mui/material/styles';
import { bgBlur } from 'src/theme/css';
// ----------------------------------------------------------------------
export const StyledArrow = styled('span')(({ arrow, theme }) => {
    const SIZE = 14;
    const POSITION = -(SIZE / 2) + 0.5;
    const topStyle = {
        top: POSITION,
        transform: 'rotate(135deg)',
    };
    const bottomStyle = {
        bottom: POSITION,
        transform: 'rotate(-45deg)',
    };
    const leftStyle = {
        left: POSITION,
        transform: 'rotate(45deg)',
    };
    const rightStyle = {
        right: POSITION,
        transform: 'rotate(-135deg)',
    };
    return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ width: SIZE, height: SIZE, position: 'absolute', borderBottomLeftRadius: SIZE / 4, clipPath: 'polygon(0% 0%, 100% 100%, 0% 100%)', border: `solid 1px ${alpha(theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.common.black, 0.12)}` }, bgBlur({
        color: theme.palette.background.paper,
    })), (arrow === 'top-left' && Object.assign(Object.assign({}, topStyle), { left: 20 }))), (arrow === 'top-center' && Object.assign(Object.assign({}, topStyle), { left: 0, right: 0, margin: 'auto' }))), (arrow === 'top-right' && Object.assign(Object.assign({}, topStyle), { right: 20 }))), (arrow === 'bottom-left' && Object.assign(Object.assign({}, bottomStyle), { left: 20 }))), (arrow === 'bottom-center' && Object.assign(Object.assign({}, bottomStyle), { left: 0, right: 0, margin: 'auto' }))), (arrow === 'bottom-right' && Object.assign(Object.assign({}, bottomStyle), { right: 20 }))), (arrow === 'left-top' && Object.assign(Object.assign({}, leftStyle), { top: 20 }))), (arrow === 'left-center' && Object.assign(Object.assign({}, leftStyle), { top: 0, bottom: 0, margin: 'auto' }))), (arrow === 'left-bottom' && Object.assign(Object.assign({}, leftStyle), { bottom: 20 }))), (arrow === 'right-top' && Object.assign(Object.assign({}, rightStyle), { top: 20 }))), (arrow === 'right-center' && Object.assign(Object.assign({}, rightStyle), { top: 0, bottom: 0, margin: 'auto' }))), (arrow === 'right-bottom' && Object.assign(Object.assign({}, rightStyle), { bottom: 20 })));
});
