import { listClasses } from '@mui/material/List';
import { paper } from '../../css';
// ----------------------------------------------------------------------
export function popover(theme) {
    return {
        MuiPopover: {
            styleOverrides: {
                paper: Object.assign(Object.assign({}, paper({ theme, dropdown: true })), { [`& .${listClasses.root}`]: {
                        paddingTop: 0,
                        paddingBottom: 0,
                    } }),
            },
        },
    };
}
