import { alpha } from '@mui/material/styles';
import { fabClasses } from '@mui/material/Fab';
// ----------------------------------------------------------------------
const COLORS = ['primary', 'secondary', 'info', 'success', 'warning', 'error'];
// ----------------------------------------------------------------------
export function fab(theme) {
    const lightMode = theme.palette.mode === 'light';
    const rootStyles = (ownerState) => {
        const defaultColor = ownerState.color === 'default';
        const inheritColor = ownerState.color === 'inherit';
        const circularVariant = ownerState.variant === 'circular';
        const extendedVariant = ownerState.variant === 'extended';
        const outlinedVariant = ownerState.variant === 'outlined';
        const outlinedExtendedVariant = ownerState.variant === 'outlinedExtended';
        const softVariant = ownerState.variant === 'soft';
        const softExtendedVariant = ownerState.variant === 'softExtended';
        const defaultStyle = Object.assign(Object.assign(Object.assign({ '&:hover, &:active': {
                boxShadow: 'none',
            } }, ((circularVariant || extendedVariant) && Object.assign(Object.assign({}, ((defaultColor || inheritColor) && {
            boxShadow: theme.customShadows.z8,
        })), (inheritColor && {
            backgroundColor: theme.palette.text.primary,
            color: lightMode ? theme.palette.common.white : theme.palette.grey[800],
            '&:hover': {
                backgroundColor: lightMode ? theme.palette.grey[700] : theme.palette.grey[400],
            },
        })))), ((outlinedVariant || outlinedExtendedVariant) && Object.assign(Object.assign(Object.assign({ boxShadow: 'none', backgroundColor: 'transparent' }, ((defaultColor || inheritColor) && {
            border: `solid 1px ${alpha(theme.palette.grey[500], 0.32)}`,
        })), (defaultColor && Object.assign({}, (!lightMode && {
            color: theme.palette.text.secondary,
        })))), { '&:hover': {
                borderColor: 'currentColor',
                boxShadow: '0 0 0 0.5px currentColor',
                backgroundColor: theme.palette.action.hover,
            } }))), ((softVariant || softExtendedVariant) && Object.assign(Object.assign({ boxShadow: 'none' }, (defaultColor && {
            color: theme.palette.grey[800],
            backgroundColor: theme.palette.grey[300],
            '&:hover': {
                backgroundColor: theme.palette.grey[400],
            },
        })), (inheritColor && {
            backgroundColor: alpha(theme.palette.grey[500], 0.08),
            '&:hover': {
                backgroundColor: alpha(theme.palette.grey[500], 0.24),
            },
        }))));
        const colorStyle = COLORS.map((color) => (Object.assign({}, (ownerState.color === color && Object.assign(Object.assign(Object.assign({}, ((circularVariant || extendedVariant) && {
            boxShadow: theme.customShadows[color],
            '&:hover': {
                backgroundColor: theme.palette[color].dark,
            },
        })), ((outlinedVariant || outlinedExtendedVariant) && {
            color: theme.palette[color].main,
            border: `solid 1px ${alpha(theme.palette[color].main, 0.48)}`,
            '&:hover': {
                backgroundColor: alpha(theme.palette[color].main, 0.08),
            },
        })), ((softVariant || softExtendedVariant) && {
            color: theme.palette[color][lightMode ? 'dark' : 'light'],
            backgroundColor: alpha(theme.palette[color].main, 0.16),
            '&:hover': {
                backgroundColor: alpha(theme.palette[color].main, 0.32),
            },
        }))))));
        const disabledState = {
            [`&.${fabClasses.disabled}`]: Object.assign({}, ((outlinedVariant || outlinedExtendedVariant) && {
                backgroundColor: 'transparent',
                border: `solid 1px ${theme.palette.action.disabledBackground}`,
            })),
        };
        const size = Object.assign({}, ((extendedVariant || outlinedExtendedVariant || softExtendedVariant) && Object.assign(Object.assign(Object.assign({ width: 'auto', '& svg': {
                marginRight: theme.spacing(1),
            } }, (ownerState.size === 'small' && {
            height: 34,
            minHeight: 34,
            borderRadius: 17,
            padding: theme.spacing(0, 1),
        })), (ownerState.size === 'medium' && {
            height: 40,
            minHeight: 40,
            borderRadius: 20,
            padding: theme.spacing(0, 2),
        })), (ownerState.size === 'large' && {
            height: 48,
            minHeight: 48,
            borderRadius: 24,
            padding: theme.spacing(0, 2),
        }))));
        return [defaultStyle, ...colorStyle, disabledState, size];
    };
    return {
        MuiFab: {
            styleOverrides: {
                root: ({ ownerState }) => rootStyles(ownerState),
            },
        },
    };
}
