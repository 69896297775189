import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState, useEffect, useCallback } from 'react';
import Stack from '@mui/material/Stack';
import Popover from '@mui/material/Popover';
import { usePathname } from 'src/routes/hooks';
import { useActiveLink } from 'src/routes/hooks/use-active-link';
import NavItem from './nav-item';
// ----------------------------------------------------------------------
export default function NavList({ data, depth, slotProps }) {
    const navRef = useRef(null);
    const pathname = usePathname();
    const active = useActiveLink(data.path, !!data.children);
    const [openMenu, setOpenMenu] = useState(false);
    useEffect(() => {
        if (openMenu) {
            handleCloseMenu();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);
    const handleOpenMenu = useCallback(() => {
        if (data.children) {
            setOpenMenu(true);
        }
    }, [data.children]);
    const handleCloseMenu = useCallback(() => {
        setOpenMenu(false);
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(NavItem, { ref: navRef, open: openMenu, onMouseEnter: handleOpenMenu, onMouseLeave: handleCloseMenu, 
                //
                title: data.title, path: data.path, icon: data.icon, info: data.info, roles: data.roles, caption: data.caption, disabled: data.disabled, 
                //
                depth: depth, hasChild: !!data.children, externalLink: data.path.includes('http'), currentRole: slotProps === null || slotProps === void 0 ? void 0 : slotProps.currentRole, 
                //
                active: active, className: active ? 'active' : '', sx: depth === 1 ? slotProps === null || slotProps === void 0 ? void 0 : slotProps.rootItem : slotProps === null || slotProps === void 0 ? void 0 : slotProps.subItem }), !!data.children && (_jsx(Popover, { disableScrollLock: true, open: openMenu, anchorEl: navRef.current, anchorOrigin: depth === 1
                    ? { vertical: 'bottom', horizontal: 'left' }
                    : { vertical: 'center', horizontal: 'right' }, transformOrigin: depth === 1
                    ? { vertical: 'top', horizontal: 'left' }
                    : { vertical: 'center', horizontal: 'left' }, slotProps: {
                    paper: {
                        onMouseEnter: handleOpenMenu,
                        onMouseLeave: handleCloseMenu,
                        sx: Object.assign({ minWidth: 160 }, (openMenu && {
                            pointerEvents: 'auto',
                        })),
                    },
                }, sx: {
                    pointerEvents: 'none',
                }, children: _jsx(NavSubList, { data: data.children, depth: depth, slotProps: slotProps }) }))] }));
}
// ----------------------------------------------------------------------
function NavSubList({ data, depth, slotProps }) {
    return (_jsx(Stack, { spacing: 0.5, children: data.map((list) => (_jsx(NavList, { data: list, depth: depth + 1, slotProps: slotProps }, list.title))) }));
}
