import { _mock } from 'src/_mock';
// TO GET THE USER FROM THE AUTHCONTEXT, YOU CAN USE
// CHANGE:
// import { useMockedUser } from 'src/hooks/use-mocked-user';
// const { user } = useMockedUser();
// TO:
import { useAuthContext } from 'src/auth/hooks';
// const { user } = useAuthContext();
// ----------------------------------------------------------------------
export function useMockedUser() {
    var _a;
    const { user } = useAuthContext();
    const userData = {
        id: '8864c717-587d-472a-929a-8e5f298024da-0',
        displayName: (_a = user === null || user === void 0 ? void 0 : user.payload) === null || _a === void 0 ? void 0 : _a.email,
        email: user === null || user === void 0 ? void 0 : user.payload.email,
        password: 'demo1235',
        photoURL: _mock.image.avatar(24),
        phoneNumber: '+40 777666555',
        country: 'United States',
        address: '90210 Broadway Blvd',
        state: 'California',
        city: 'San Francisco',
        zipCode: '94116',
        about: 'Praesent turpis. Phasellus viverra nulla ut metus varius laoreet. Phasellus tempus.',
        role: 'admin',
        isPublic: true,
    };
    return { user: userData };
}
