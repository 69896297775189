// ----------------------------------------------------------------------
export function treeView(theme) {
    return {
        MuiTreeItem: {
            styleOverrides: {
                label: Object.assign({}, theme.typography.body2),
                iconContainer: {
                    width: 'auto',
                },
            },
        },
    };
}
