import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
import { useSettingsContext } from 'src/components/settings';
import Main from './main';
import Header from './header';
import NavMini from './nav-mini';
import NavVertical from './nav-vertical';
import NavHorizontal from './nav-horizontal';
export default function DashboardLayout({ children }) {
    const settings = useSettingsContext();
    const lgUp = useResponsive('up', 'lg');
    const nav = useBoolean();
    const isHorizontal = settings.themeLayout === 'horizontal';
    const isMini = settings.themeLayout === 'mini';
    const renderNavMini = _jsx(NavMini, {});
    const renderHorizontal = _jsx(NavHorizontal, {});
    const renderNavVertical = _jsx(NavVertical, { openNav: nav.value, onCloseNav: nav.onFalse });
    if (isHorizontal) {
        return (_jsxs(_Fragment, { children: [_jsx(Header, { onOpenNav: nav.onTrue }), lgUp ? renderHorizontal : renderNavVertical, _jsx(Main, { children: children })] }));
    }
    if (isMini) {
        return (_jsxs(_Fragment, { children: [_jsx(Header, { onOpenNav: nav.onTrue }), _jsxs(Box, { sx: {
                        minHeight: 1,
                        display: 'flex',
                        flexDirection: { xs: 'column', lg: 'row' },
                    }, children: [lgUp ? renderNavMini : renderNavVertical, _jsx(Main, { children: children })] })] }));
    }
    return (_jsxs(_Fragment, { children: [_jsx(Header, { onOpenNav: nav.onTrue }), _jsxs(Box, { sx: {
                    minHeight: 1,
                    display: 'flex',
                    flexDirection: { xs: 'column', lg: 'row' },
                }, children: [renderNavVertical, _jsx(Main, { children: children })] })] }));
}
