import { jsx as _jsx } from "react/jsx-runtime";
import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import CompactLayout from 'src/layouts/compact';
// ----------------------------------------------------------------------
const Page404 = lazy(() => import('src/pages/404'));
// ----------------------------------------------------------------------
export const mainRoutes = [
    {
        element: (_jsx(CompactLayout, { children: _jsx(Outlet, {}) })),
        children: [{ path: '404', element: _jsx(Page404, {}) }],
    },
];
