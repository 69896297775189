import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
export default function RTL({ children, themeDirection }) {
    useEffect(() => {
        document.dir = themeDirection;
    }, [themeDirection]);
    const cacheRtl = createCache({
        key: 'rtl',
        prepend: true,
        // @ts-ignore
        // https://github.com/styled-components/stylis-plugin-rtl/issues/35
        stylisPlugins: [rtlPlugin],
    });
    if (themeDirection === 'rtl') {
        return _jsx(CacheProvider, { value: cacheRtl, children: children });
    }
    return _jsx(_Fragment, { children: children });
}
