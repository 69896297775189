import { jsx as _jsx } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import { alpha } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';
import { presetOptions } from 'src/theme/options/presets';
export default function PresetsOptions({ value, onChange }) {
    return (_jsx(Box, { columnGap: 2, rowGap: 1.5, display: "grid", gridTemplateColumns: "repeat(3, 1fr)", children: presetOptions.map((option) => {
            const selected = value === option.name;
            return (_jsx(ButtonBase, { onClick: () => onChange(option.name), sx: Object.assign({ height: 56, borderRadius: 1, border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.08)}` }, (selected && {
                    borderColor: 'transparent',
                    bgcolor: alpha(option.value, 0.08),
                })), children: _jsx(Box, { sx: Object.assign({ width: 12, height: 12, borderRadius: '50%', bgcolor: option.value, transition: (theme) => theme.transitions.create(['transform'], {
                            duration: theme.transitions.duration.shorter,
                        }) }, (selected && {
                        transform: 'scale(2)',
                    })) }) }, option.name));
        }) }));
}
