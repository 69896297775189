var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { fetchAuthSession } from '@aws-amplify/auth';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useMemo, useEffect, useContext, useReducer, useCallback } from 'react';
import { setSession } from './utils';
import { AuthContext } from './auth-context';
// ----------------------------------------------------------------------
/**
 * NOTE:
 * We only build demo at basic level.
 * Customer will need to do some extra handling yourself if you want to extend the logic and other features...
 */
// ----------------------------------------------------------------------
var Types;
(function (Types) {
    Types["INITIAL"] = "INITIAL";
    Types["LOGIN"] = "LOGIN";
    Types["REGISTER"] = "REGISTER";
    Types["LOGOUT"] = "LOGOUT";
})(Types || (Types = {}));
// ----------------------------------------------------------------------
const initialState = {
    user: null,
    loading: true,
};
const reducer = (state, action) => {
    if (action.type === Types.INITIAL) {
        return {
            loading: false,
            user: action.payload.user,
        };
    }
    if (action.type === Types.LOGIN) {
        return Object.assign(Object.assign({}, state), { user: action.payload.user });
    }
    if (action.type === Types.REGISTER) {
        return Object.assign(Object.assign({}, state), { user: action.payload.user });
    }
    if (action.type === Types.LOGOUT) {
        return Object.assign(Object.assign({}, state), { user: null });
    }
    return state;
};
export const AuthProvider = ({ children, signOut, user }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { user: authUser, authStatus } = useAuthenticator((context) => [
        context.user,
        context.authStatus,
    ]);
    useEffect(() => {
        const configureSession = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b;
            if (authStatus === 'authenticated' && authUser) {
                const session = yield fetchAuthSession();
                const accessToken = (_a = session.tokens) === null || _a === void 0 ? void 0 : _a.accessToken;
                setSession((accessToken || '').toString());
                dispatch({
                    type: Types.LOGIN,
                    payload: { user: Object.assign(Object.assign({}, authUser), (((_b = session === null || session === void 0 ? void 0 : session.tokens) === null || _b === void 0 ? void 0 : _b.idToken) || {})) },
                });
            }
            else {
                setSession(null);
                dispatch({ type: Types.LOGOUT });
            }
        });
        configureSession();
    }, [authStatus, authUser]);
    // TODO: remove methods 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const login = (email, password) => __awaiter(void 0, void 0, void 0, function* () {
        // Empty login as handled in cognito
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const logout = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        signOut();
        setSession(null);
        dispatch({
            type: Types.LOGOUT,
        });
    }), [signOut]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const register = (email, password) => __awaiter(void 0, void 0, void 0, function* () {
        // Empty register as handled in cognito
    });
    const memoizedValue = useMemo(() => ({
        user: state.user,
        method: 'jwt',
        loading: false,
        authenticated: authStatus === 'authenticated',
        unauthenticated: authStatus === 'unauthenticated',
        login,
        register,
        logout,
    }), [authStatus, login, logout, register, state.user]);
    const token = sessionStorage.getItem('accessToken');
    if (!token)
        return _jsx(_Fragment, { children: "Logging to session...." });
    return _jsx(AuthContext.Provider, { value: memoizedValue, children: children });
};
// Custom hook to use the auth context
export const useAuth = () => useContext(AuthContext);
