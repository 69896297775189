import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Header from '../common/header-simple';
export default function CompactLayout({ children }) {
    return (_jsxs(_Fragment, { children: [_jsx(Header, {}), _jsx(Container, { component: "main", children: _jsx(Stack, { sx: {
                        py: 12,
                        m: 'auto',
                        maxWidth: 400,
                        minHeight: '100vh',
                        textAlign: 'center',
                        justifyContent: 'center',
                    }, children: children }) })] }));
}
