var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import { useResponsive } from 'src/hooks/use-responsive';
import { useSettingsContext } from 'src/components/settings';
import { NAV, HEADER } from '../config-layout';
// ----------------------------------------------------------------------
const SPACING = 8;
export default function Main(_a) {
    var { children, sx } = _a, other = __rest(_a, ["children", "sx"]);
    const settings = useSettingsContext();
    const lgUp = useResponsive('up', 'lg');
    const isNavHorizontal = settings.themeLayout === 'horizontal';
    const isNavMini = settings.themeLayout === 'mini';
    if (isNavHorizontal) {
        return (_jsx(Box, { component: "main", sx: Object.assign({ minHeight: 1, display: 'flex', flexDirection: 'column', pt: `${HEADER.H_MOBILE + 24}px`, pb: 10 }, (lgUp && {
                pt: `${HEADER.H_MOBILE * 2 + 40}px`,
                pb: 15,
            })), children: children }));
    }
    return (_jsx(Box, Object.assign({ component: "main", sx: Object.assign(Object.assign({ flexGrow: 1, minHeight: 1, display: 'flex', flexDirection: 'column', py: `${HEADER.H_MOBILE + SPACING}px` }, (lgUp && Object.assign({ px: 2, py: `${HEADER.H_DESKTOP + SPACING}px`, width: `calc(100% - ${NAV.W_VERTICAL}px)` }, (isNavMini && {
            width: `calc(100% - ${NAV.W_MINI}px)`,
        })))), sx) }, other, { children: children })));
}
