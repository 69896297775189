var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { m } from 'framer-motion';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import { alpha } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useRouter } from 'src/routes/hooks';
import { useMockedUser } from 'src/hooks/use-mocked-user';
import { useAuthContext } from 'src/auth/hooks';
import { varHover } from 'src/components/animate';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
// ----------------------------------------------------------------------
const OPTIONS = [
    {
        label: 'Home',
        linkTo: '/',
    },
    // {
    //   label: 'Settings',
    //   linkTo: '/user/settings',
    // },
];
// ----------------------------------------------------------------------
export default function AccountPopover() {
    var _a;
    const router = useRouter();
    const { user } = useMockedUser();
    const { logout } = useAuthContext();
    const popover = usePopover();
    const handleLogout = () => __awaiter(this, void 0, void 0, function* () {
        try {
            yield logout();
            popover.onClose();
            router.replace('/');
        }
        catch (error) {
            console.error(error);
        }
    });
    const handleClickItem = (path) => {
        popover.onClose();
        router.push(path);
    };
    return (_jsxs(_Fragment, { children: [_jsx(IconButton, { component: m.button, whileTap: "tap", whileHover: "hover", variants: varHover(1.05), onClick: popover.onOpen, sx: Object.assign({ width: 40, height: 40, background: (theme) => alpha(theme.palette.grey[500], 0.08) }, (popover.open && {
                    background: (theme) => `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
                })), children: _jsx(Avatar, { src: user === null || user === void 0 ? void 0 : user.photoURL, alt: user === null || user === void 0 ? void 0 : user.displayName, sx: {
                        width: 36,
                        height: 36,
                        border: (theme) => `solid 2px ${theme.palette.background.default}`,
                    }, children: (_a = user === null || user === void 0 ? void 0 : user.displayName) === null || _a === void 0 ? void 0 : _a.charAt(0).toUpperCase() }) }), _jsxs(CustomPopover, { open: popover.open, onClose: popover.onClose, sx: { width: 200, p: 0 }, children: [_jsxs(Box, { sx: { p: 2, pb: 1.5 }, children: [_jsx(Typography, { variant: "subtitle2", noWrap: true, children: user === null || user === void 0 ? void 0 : user.displayName }), _jsx(Typography, { variant: "body2", sx: { color: 'text.secondary' }, noWrap: true, children: user === null || user === void 0 ? void 0 : user.email })] }), _jsx(Divider, { sx: { borderStyle: 'dashed' } }), _jsx(Stack, { sx: { p: 1 }, children: OPTIONS.map((option) => (_jsx(MenuItem, { onClick: () => handleClickItem(option.linkTo), children: option.label }, option.label))) }), _jsx(Divider, { sx: { borderStyle: 'dashed' } }), _jsx(MenuItem, { onClick: handleLogout, sx: { m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }, children: "Logout" })] })] }));
}
