import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useMockedUser } from 'src/hooks/use-mocked-user';
import { hideScroll } from 'src/theme/css';
import Logo from 'src/components/logo';
import { NavSectionMini } from 'src/components/nav-section';
import { NAV } from '../config-layout';
import { useNavData } from './config-navigation';
import NavToggleButton from '../common/nav-toggle-button';
// ----------------------------------------------------------------------
export default function NavMini() {
    const { user } = useMockedUser();
    const navData = useNavData();
    return (_jsxs(Box, { sx: {
            flexShrink: { lg: 0 },
            width: { lg: NAV.W_MINI },
        }, children: [_jsx(NavToggleButton, { sx: {
                    top: 22,
                    left: NAV.W_MINI - 12,
                } }), _jsxs(Stack, { sx: Object.assign({ pb: 2, height: 1, position: 'fixed', width: NAV.W_MINI, borderRight: (theme) => `dashed 1px ${theme.palette.divider}` }, hideScroll.x), children: [_jsx(Logo, { sx: { mx: 'auto', my: 2 } }), _jsx(NavSectionMini, { data: navData, slotProps: {
                            currentRole: user === null || user === void 0 ? void 0 : user.role,
                        } })] })] }));
}
