var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { memo } from 'react';
import Stack from '@mui/material/Stack';
import NavList from './nav-list';
// ----------------------------------------------------------------------
function NavSectionHorizontal(_a) {
    var { data, slotProps, sx } = _a, other = __rest(_a, ["data", "slotProps", "sx"]);
    return (_jsx(Stack, Object.assign({ component: "nav", id: "nav-section-horizontal", direction: "row", alignItems: "center", spacing: `${(slotProps === null || slotProps === void 0 ? void 0 : slotProps.gap) || 6}px`, sx: Object.assign({ mx: 'auto' }, sx) }, other, { children: data.map((group, index) => (_jsx(Group, { items: group.items, slotProps: slotProps }, group.subheader || index))) })));
}
export default memo(NavSectionHorizontal);
// ----------------------------------------------------------------------
function Group({ items, slotProps }) {
    return (_jsx(_Fragment, { children: items.map((list) => (_jsx(NavList, { data: list, depth: 1, slotProps: slotProps }, list.title))) }));
}
