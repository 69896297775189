import { jsx as _jsx } from "react/jsx-runtime";
import { buttonClasses } from '@mui/material/Button';
import Iconify from 'src/components/iconify';
// ----------------------------------------------------------------------
const dateList = [
    'DatePicker',
    'DateTimePicker',
    'StaticDatePicker',
    'DesktopDatePicker',
    'DesktopDateTimePicker',
    //
    'MobileDatePicker',
    'MobileDateTimePicker',
];
const timeList = ['TimePicker', 'MobileTimePicker', 'StaticTimePicker', 'DesktopTimePicker'];
const switchIcon = () => _jsx(Iconify, { icon: "eva:chevron-down-fill", width: 24 });
const leftIcon = () => _jsx(Iconify, { icon: "eva:arrow-ios-back-fill", width: 24 });
const rightIcon = () => _jsx(Iconify, { icon: "eva:arrow-ios-forward-fill", width: 24 });
const calendarIcon = () => _jsx(Iconify, { icon: "solar:calendar-mark-bold-duotone", width: 24 });
const clockIcon = () => _jsx(Iconify, { icon: "solar:clock-circle-outline", width: 24 });
const desktopTypes = dateList.reduce((result, currentValue) => {
    result[`Mui${currentValue}`] = {
        defaultProps: {
            slots: {
                openPickerIcon: calendarIcon,
                leftArrowIcon: leftIcon,
                rightArrowIcon: rightIcon,
                switchViewIcon: switchIcon,
            },
        },
    };
    return result;
}, {});
const timeTypes = timeList.reduce((result, currentValue) => {
    result[`Mui${currentValue}`] = {
        defaultProps: {
            slots: {
                openPickerIcon: clockIcon,
                rightArrowIcon: rightIcon,
                switchViewIcon: switchIcon,
            },
        },
    };
    return result;
}, {});
export function datePicker(theme) {
    return Object.assign(Object.assign({ MuiPickersLayout: {
            styleOverrides: {
                root: {
                    '& .MuiPickersLayout-actionBar': {
                        [`& .${buttonClasses.root}:last-of-type`]: {
                            backgroundColor: theme.palette.text.primary,
                            color: theme.palette.mode === 'light'
                                ? theme.palette.common.white
                                : theme.palette.grey[800],
                        },
                    },
                },
            },
        } }, desktopTypes), timeTypes);
}
