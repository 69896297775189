import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { paths } from 'src/routes/paths';
import { useMockedUser } from 'src/hooks/use-mocked-user';
import Label from 'src/components/label';
// ----------------------------------------------------------------------
export default function NavUpgrade() {
    var _a;
    const { user } = useMockedUser();
    return (_jsx(Stack, { sx: {
            px: 2,
            py: 5,
            textAlign: 'center',
        }, children: _jsxs(Stack, { alignItems: "center", children: [_jsxs(Box, { sx: { position: 'relative' }, children: [_jsx(Avatar, { src: user === null || user === void 0 ? void 0 : user.photoURL, alt: user === null || user === void 0 ? void 0 : user.displayName, sx: { width: 48, height: 48 }, children: (_a = user === null || user === void 0 ? void 0 : user.displayName) === null || _a === void 0 ? void 0 : _a.charAt(0).toUpperCase() }), _jsx(Label, { color: "success", variant: "filled", sx: {
                                top: -6,
                                px: 0.5,
                                left: 40,
                                height: 20,
                                position: 'absolute',
                                borderBottomLeftRadius: 2,
                            }, children: "Free" })] }), _jsxs(Stack, { spacing: 0.5, sx: { mb: 2, mt: 1.5, width: 1 }, children: [_jsx(Typography, { variant: "subtitle2", noWrap: true, children: user === null || user === void 0 ? void 0 : user.displayName }), _jsx(Typography, { variant: "body2", noWrap: true, sx: { color: 'text.disabled' }, children: user === null || user === void 0 ? void 0 : user.email })] }), _jsx(Button, { disabled: true, variant: "contained", href: paths.upgradeToPro, target: "_blank", rel: "noopener", children: "Upgrade to Pro" })] }) }));
}
