// ----------------------------------------------------------------------
export function flattenArray(list, key = 'children') {
    let children = [];
    const flatten = list === null || list === void 0 ? void 0 : list.map((item) => {
        if (item[key] && item[key].length) {
            children = [...children, ...item[key]];
        }
        return item;
    });
    return flatten === null || flatten === void 0 ? void 0 : flatten.concat(children.length ? flattenArray(children, key) : children);
}
