import { jsx as _jsx } from "react/jsx-runtime";
import { useRef } from 'react';
import { closeSnackbar, SnackbarProvider as NotistackProvider } from 'notistack';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Iconify from '../iconify';
import { useSettingsContext } from '../settings';
import { StyledIcon, StyledNotistack } from './styles';
export default function SnackbarProvider({ children }) {
    const settings = useSettingsContext();
    const isRTL = settings.themeDirection === 'rtl';
    const notistackRef = useRef(null);
    return (_jsx(NotistackProvider, { ref: notistackRef, maxSnack: 5, preventDuplicate: true, autoHideDuration: 3000, TransitionComponent: isRTL ? Collapse : undefined, variant: "success" // Set default variant
        , anchorOrigin: { vertical: 'top', horizontal: 'right' }, iconVariant: {
            info: (_jsx(StyledIcon, { color: "info", children: _jsx(Iconify, { icon: "eva:info-fill", width: 24 }) })),
            success: (_jsx(StyledIcon, { color: "success", children: _jsx(Iconify, { icon: "eva:checkmark-circle-2-fill", width: 24 }) })),
            warning: (_jsx(StyledIcon, { color: "warning", children: _jsx(Iconify, { icon: "eva:alert-triangle-fill", width: 24 }) })),
            error: (_jsx(StyledIcon, { color: "error", children: _jsx(Iconify, { icon: "solar:danger-bold", width: 24 }) })),
        }, Components: {
            default: StyledNotistack,
            info: StyledNotistack,
            success: StyledNotistack,
            warning: StyledNotistack,
            error: StyledNotistack,
        }, 
        // with close as default
        action: (snackbarId) => (_jsx(IconButton, { size: "small", onClick: () => closeSnackbar(snackbarId), sx: { p: 0.5 }, children: _jsx(Iconify, { width: 16, icon: "mingcute:close-line" }) })), children: children }));
}
