// ----------------------------------------------------------------------
const ROOTS = {
    AUTH: '/auth',
    DASHBOARD: '/dashboard',
};
// ----------------------------------------------------------------------
export const paths = {
    upgradeToPro: 'upgrade-to-pro',
    // AUTH
    auth: {
        jwt: {
            login: `${ROOTS.AUTH}/jwt/login`,
            register: `${ROOTS.AUTH}/jwt/register`,
        },
    },
    // DASHBOARD
    dashboard: {
        root: ROOTS.DASHBOARD,
        one: `${ROOTS.DASHBOARD}/one`,
        two: `${ROOTS.DASHBOARD}/two`,
        mock: `${ROOTS.DASHBOARD}/mock`,
        interviews: {
            index: `${ROOTS.DASHBOARD}/interviews`,
            new: `${ROOTS.DASHBOARD}/interviews/new`,
            edit: (id) => `${ROOTS.DASHBOARD}/interviews/edit/${id}`,
            start: (id) => `${ROOTS.DASHBOARD}/interviews/start/${id}`,
        },
        roles: {
            index: `${ROOTS.DASHBOARD}/roles`,
            new: `${ROOTS.DASHBOARD}/roles/new`,
            edit: (id) => `${ROOTS.DASHBOARD}/roles/${id}`,
        },
        resources: {
            index: `${ROOTS.DASHBOARD}/resources`,
            new: `${ROOTS.DASHBOARD}/resources/new`,
        },
        group: {
            root: `${ROOTS.DASHBOARD}/group`,
            six: `${ROOTS.DASHBOARD}/group/six`,
        },
    },
};
