import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Drawer, { drawerClasses } from '@mui/material/Drawer';
import { paper } from 'src/theme/css';
import Iconify from '../../iconify';
import Scrollbar from '../../scrollbar';
import BaseOptions from './base-option';
import LayoutOptions from './layout-options';
import PresetsOptions from './presets-options';
import StretchOptions from './stretch-options';
import { useSettingsContext } from '../context';
import FullScreenOption from './fullscreen-option';
// ----------------------------------------------------------------------
export default function SettingsDrawer() {
    const theme = useTheme();
    const settings = useSettingsContext();
    const labelStyles = {
        mb: 1.5,
        color: 'text.disabled',
        fontWeight: 'fontWeightSemiBold',
    };
    const renderHead = (_jsxs(Stack, { direction: "row", alignItems: "center", justifyContent: "space-between", sx: { py: 2, pr: 1, pl: 2.5 }, children: [_jsx(Typography, { variant: "h6", sx: { flexGrow: 1 }, children: "Settings" }), _jsx(Tooltip, { title: "Reset", children: _jsx(IconButton, { onClick: settings.onReset, children: _jsx(Badge, { color: "error", variant: "dot", invisible: !settings.canReset, children: _jsx(Iconify, { icon: "solar:restart-bold" }) }) }) }), _jsx(IconButton, { onClick: settings.onClose, children: _jsx(Iconify, { icon: "mingcute:close-line" }) })] }));
    const renderMode = (_jsxs("div", { children: [_jsx(Typography, { variant: "caption", component: "div", sx: Object.assign({}, labelStyles), children: "Mode" }), _jsx(BaseOptions, { value: settings.themeMode, onChange: (newValue) => settings.onUpdate('themeMode', newValue), options: ['light', 'dark'], icons: ['sun', 'moon'] })] }));
    const renderContrast = (_jsxs("div", { children: [_jsx(Typography, { variant: "caption", component: "div", sx: Object.assign({}, labelStyles), children: "Contrast" }), _jsx(BaseOptions, { value: settings.themeContrast, onChange: (newValue) => settings.onUpdate('themeContrast', newValue), options: ['default', 'bold'], icons: ['contrast', 'contrast_bold'] })] }));
    const renderDirection = (_jsxs("div", { children: [_jsx(Typography, { variant: "caption", component: "div", sx: Object.assign({}, labelStyles), children: "Direction" }), _jsx(BaseOptions, { value: settings.themeDirection, onChange: (newValue) => settings.onUpdate('themeDirection', newValue), options: ['ltr', 'rtl'], icons: ['align_left', 'align_right'] })] }));
    const renderLayout = (_jsxs("div", { children: [_jsx(Typography, { variant: "caption", component: "div", sx: Object.assign({}, labelStyles), children: "Layout" }), _jsx(LayoutOptions, { value: settings.themeLayout, onChange: (newValue) => settings.onUpdate('themeLayout', newValue), options: ['vertical', 'horizontal', 'mini'] })] }));
    const renderStretch = (_jsxs("div", { children: [_jsxs(Typography, { variant: "caption", component: "div", sx: Object.assign(Object.assign({}, labelStyles), { display: 'inline-flex', alignItems: 'center' }), children: ["Stretch", _jsx(Tooltip, { title: "Only available at large resolutions > 1600px (xl)", children: _jsx(Iconify, { icon: "eva:info-outline", width: 16, sx: { ml: 0.5 } }) })] }), _jsx(StretchOptions, { value: settings.themeStretch, onChange: () => settings.onUpdate('themeStretch', !settings.themeStretch) })] }));
    const renderPresets = (_jsxs("div", { children: [_jsx(Typography, { variant: "caption", component: "div", sx: Object.assign({}, labelStyles), children: "Presets" }), _jsx(PresetsOptions, { value: settings.themeColorPresets, onChange: (newValue) => settings.onUpdate('themeColorPresets', newValue) })] }));
    return (_jsxs(Drawer, { anchor: "right", open: settings.open, onClose: settings.onClose, slotProps: {
            backdrop: { invisible: true },
        }, sx: {
            [`& .${drawerClasses.paper}`]: Object.assign(Object.assign({}, paper({ theme, bgcolor: theme.palette.background.default })), { width: 280 }),
        }, children: [renderHead, _jsx(Divider, { sx: { borderStyle: 'dashed' } }), _jsx(Scrollbar, { children: _jsxs(Stack, { spacing: 3, sx: { p: 3 }, children: [renderMode, renderContrast, renderDirection, renderLayout, renderStretch, renderPresets] }) }), _jsx(FullScreenOption, {})] }));
}
