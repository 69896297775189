var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import { alpha, styled } from '@mui/material/styles';
import ListItemButton from '@mui/material/ListItemButton';
import { RouterLink } from 'src/routes/components';
import Iconify from '../../iconify';
// ----------------------------------------------------------------------
const NavItem = forwardRef((_a, ref) => {
    var { title, path, icon, info, disabled, caption, roles, 
    //
    open, depth, active, hasChild, externalLink, currentRole = 'admin' } = _a, other = __rest(_a, ["title", "path", "icon", "info", "disabled", "caption", "roles", "open", "depth", "active", "hasChild", "externalLink", "currentRole"]);
    const subItem = depth !== 1;
    const renderContent = (_jsxs(StyledNavItem, Object.assign({ disableGutters: true, ref: ref, open: open, depth: depth, active: active, disabled: disabled }, other, { children: [icon && (_jsx(Box, { component: "span", className: "icon", children: icon })), title && (_jsx(Box, { component: "span", className: "label", children: title })), caption && (_jsx(Tooltip, { title: caption, arrow: true, placement: "right", children: _jsx(Iconify, { width: 16, icon: "eva:info-outline", className: "caption" }) })), info && subItem && (_jsx(Box, { component: "span", className: "info", children: info })), hasChild && _jsx(Iconify, { width: 16, className: "arrow", icon: "eva:arrow-ios-forward-fill" })] })));
    // Hidden item by role
    if (roles && !roles.includes(`${currentRole}`)) {
        return null;
    }
    if (externalLink)
        return (_jsx(Link, { href: path, target: "_blank", rel: "noopener", color: "inherit", underline: "none", sx: Object.assign({ width: 1 }, (disabled && {
                cursor: 'default',
            })), children: renderContent }));
    return (_jsx(Link, { component: RouterLink, href: path, color: "inherit", underline: "none", sx: Object.assign({ width: 1 }, (disabled && {
            cursor: 'default',
        })), children: renderContent }));
});
export default NavItem;
// ----------------------------------------------------------------------
const StyledNavItem = styled(ListItemButton, {
    shouldForwardProp: (prop) => prop !== 'active',
})(({ active, open, depth, theme }) => {
    const subItem = depth !== 1;
    const opened = open && !active;
    const lightMode = theme.palette.mode === 'light';
    const noWrapStyles = {
        width: '100%',
        maxWidth: '100%',
        display: 'block',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    };
    const baseStyles = {
        item: {
            borderRadius: 6,
            color: theme.palette.text.secondary,
        },
        icon: {
            width: 22,
            height: 22,
            flexShrink: 0,
        },
        label: {
            textTransform: 'capitalize',
        },
        caption: {
            color: theme.palette.text.disabled,
        },
    };
    return Object.assign(Object.assign({}, (!subItem && Object.assign(Object.assign(Object.assign(Object.assign({}, baseStyles.item), { fontSize: 10, minHeight: 56, lineHeight: '16px', textAlign: 'center', flexDirection: 'column', justifyContent: 'center', padding: theme.spacing(0.5), margin: theme.spacing(0, 0.5), fontWeight: theme.typography.fontWeightSemiBold, '& .icon': Object.assign({}, baseStyles.icon), '& .label': Object.assign(Object.assign(Object.assign({}, noWrapStyles), baseStyles.label), { marginTop: theme.spacing(0.5) }), '& .caption': Object.assign(Object.assign({}, baseStyles.caption), { top: 11, left: 6, position: 'absolute' }), '& .arrow': {
            top: 11,
            right: 6,
            position: 'absolute',
        } }), (active && {
        fontWeight: theme.typography.fontWeightBold,
        backgroundColor: alpha(theme.palette.primary.main, 0.08),
        color: lightMode ? theme.palette.primary.main : theme.palette.primary.light,
        '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.16),
        },
    })), (opened && {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.action.hover,
    })))), (subItem && Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, baseStyles.item), theme.typography.body2), { minHeight: 34, padding: theme.spacing(0, 1), fontWeight: theme.typography.fontWeightMedium, '& .icon': Object.assign(Object.assign({}, baseStyles.icon), { marginRight: theme.spacing(1) }), '& .label': Object.assign(Object.assign({}, baseStyles.label), { flexGrow: 1 }), '& .caption': Object.assign(Object.assign({}, baseStyles.caption), { marginLeft: theme.spacing(0.75) }), '& .info': {
            display: 'inline-flex',
            marginLeft: theme.spacing(0.75),
        }, '& .arrow': {
            marginLeft: theme.spacing(0.75),
            marginRight: theme.spacing(-0.5),
        } }), (active && {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.action.selected,
        fontWeight: theme.typography.fontWeightSemiBold,
    })), (opened && {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.action.hover,
    }))));
});
