import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
import { bgBlur } from 'src/theme/css';
import Logo from 'src/components/logo';
import SvgColor from 'src/components/svg-color';
import { useSettingsContext } from 'src/components/settings';
import Searchbar from '../common/searchbar';
import { NAV, HEADER } from '../config-layout';
// import SettingsButton from '../common/settings-button';
import AccountPopover from '../common/account-popover';
export default function Header({ onOpenNav }) {
    const theme = useTheme();
    const settings = useSettingsContext();
    const isNavHorizontal = settings.themeLayout === 'horizontal';
    const isNavMini = settings.themeLayout === 'mini';
    const lgUp = useResponsive('up', 'lg');
    const offset = useOffSetTop(HEADER.H_DESKTOP);
    const offsetTop = offset && !isNavHorizontal;
    const renderContent = (_jsxs(_Fragment, { children: [lgUp && isNavHorizontal && _jsx(Logo, { sx: { mr: 2.5 } }), !lgUp && (_jsx(IconButton, { onClick: onOpenNav, children: _jsx(SvgColor, { src: "/assets/icons/navbar/ic_menu_item.svg" }) })), _jsx(Searchbar, {}), _jsx(Stack, { flexGrow: 1, direction: "row", alignItems: "center", justifyContent: "flex-end", spacing: { xs: 0.5, sm: 1 }, children: _jsx(AccountPopover, {}) })] }));
    return (_jsx(AppBar, { sx: Object.assign(Object.assign(Object.assign({ height: HEADER.H_MOBILE, zIndex: theme.zIndex.appBar + 1 }, bgBlur({
            color: theme.palette.background.default,
        })), { transition: theme.transitions.create(['height'], {
                duration: theme.transitions.duration.shorter,
            }) }), (lgUp && Object.assign(Object.assign(Object.assign({ width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`, height: HEADER.H_DESKTOP }, (offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
        })), (isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
        })), (isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
        })))), children: _jsx(Toolbar, { sx: {
                height: 1,
                px: { lg: 5 },
            }, children: renderContent }) }));
}
