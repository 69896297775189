var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { RouterLink } from 'src/routes/components';
import logoSource from './logo.svg';
const Logo = forwardRef((_a, ref) => {
    var { disabledLink = false, sx } = _a, other = __rest(_a, ["disabledLink", "sx"]);
    const logo = (_jsx(Box, { component: "img", src: logoSource, sx: Object.assign(Object.assign({ width: 'auto', height: 'auto' }, sx), { marginBottom: '-118px', marginTop: '-108px', marginLeft: 0, marginRight: 0 }) }));
    if (disabledLink) {
        return logo;
    }
    return (_jsx(Link, { component: RouterLink, href: "/", sx: { display: 'contents', top: 0, bottom: 0 }, children: logo }));
});
export default Logo;
