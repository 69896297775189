import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate, useRoutes } from 'react-router-dom';
import { mainRoutes } from './main';
import { authRoutes } from './auth';
import { dashboardRoutes } from './dashboard';
import { PATH_AFTER_LOGIN } from '../../config-global';
// ----------------------------------------------------------------------
export default function Router() {
    return useRoutes([
        {
            path: '/',
            element: _jsx(Navigate, { to: PATH_AFTER_LOGIN, replace: true }),
        },
        // Auth routes
        ...authRoutes,
        // Dashboard routes
        ...dashboardRoutes,
        // Main routes
        ...mainRoutes,
        // No match 404
        { path: '*', element: _jsx(Navigate, { to: "/404", replace: true }) },
    ]);
}
