import { menuItem } from '../../css';
// ----------------------------------------------------------------------
export function menu(theme) {
    return {
        MuiMenuItem: {
            styleOverrides: {
                root: Object.assign({}, menuItem(theme)),
            },
        },
    };
}
