import { jsx as _jsx } from "react/jsx-runtime";
import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';
import { LoadingScreen } from 'src/components/loading-screen';
// ----------------------------------------------------------------------
const IndexPage = lazy(() => import('src/pages/dashboard/one'));
const PageTwo = lazy(() => import('src/pages/dashboard/two'));
const PageThree = lazy(() => import('src/pages/dashboard/three'));
const PageResources = lazy(() => import('src/pages/dashboard/resources/index'));
const PageRoles = lazy(() => import('src/pages/dashboard/roles/index'));
const PageInterviews = lazy(() => import('src/pages/dashboard/interviews/index'));
const PageInterviewsNew = lazy(() => import('src/pages/dashboard/interviews/new'));
const PageInterviewsEdit = lazy(() => import('src/pages/dashboard/interviews/edit'));
const PageInterviewsStart = lazy(() => import('src/pages/dashboard/interviews/start'));
const PageNew = lazy(() => import('src/pages/dashboard/roles/new'));
const PageResourcesNew = lazy(() => import('src/pages/dashboard/resources/new'));
const PageSix = lazy(() => import('src/pages/dashboard/six'));
// ----------------------------------------------------------------------
export const dashboardRoutes = [
    {
        path: 'dashboard',
        element: (_jsx(AuthGuard, { children: _jsx(DashboardLayout, { children: _jsx(Suspense, { fallback: _jsx(LoadingScreen, {}), children: _jsx(Outlet, {}) }) }) })),
        children: [
            { element: _jsx(IndexPage, {}), index: true },
            { path: 'two', element: _jsx(PageTwo, {}) },
            { path: 'mock', element: _jsx(PageThree, {}) },
            {
                path: 'roles',
                children: [
                    { element: _jsx(PageRoles, {}), index: true },
                    { path: 'new', element: _jsx(PageNew, {}) },
                ],
            },
            {
                path: 'resources',
                children: [
                    { element: _jsx(PageResources, {}), index: true },
                    { path: 'new', element: _jsx(PageResourcesNew, {}) },
                ],
            },
            {
                path: 'interviews',
                children: [
                    { element: _jsx(PageInterviews, {}), index: true },
                    { path: 'new', element: _jsx(PageInterviewsNew, {}) },
                    { path: 'edit/:id', element: _jsx(PageInterviewsEdit, {}) },
                    { path: 'start/:id', element: _jsx(PageInterviewsStart, {}) },
                ],
            },
            {
                path: 'group',
                children: [
                    { element: _jsx(PageThree, {}), index: true },
                    { path: 'five', element: _jsx(PageRoles, {}) },
                    { path: 'six', element: _jsx(PageSix, {}) },
                ],
            },
        ],
    },
];
