import { grey } from '../palette';
import { customShadows } from '../custom-shadows';
// ----------------------------------------------------------------------
export function createContrast(contrast, mode) {
    const theme = Object.assign({}, (contrast === 'bold' &&
        mode === 'light' && {
        palette: {
            background: {
                default: grey[200],
            },
        },
    }));
    const components = Object.assign({}, (contrast === 'bold' && {
        MuiCard: {
            styleOverrides: {
                root: {
                    boxShadow: customShadows(mode).z1,
                },
            },
        },
    }));
    return Object.assign(Object.assign({}, theme), { components });
}
