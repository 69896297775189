import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect, useCallback } from 'react';
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
import { SplashScreen } from 'src/components/loading-screen';
import { useAuthContext } from '../hooks';
// ----------------------------------------------------------------------
const loginPaths = {
    jwt: paths.auth.jwt.login,
};
export default function AuthGuard({ children }) {
    const { loading } = useAuthContext();
    return _jsx(_Fragment, { children: loading ? _jsx(SplashScreen, {}) : _jsx(Container, { children: children }) });
}
// ----------------------------------------------------------------------
function Container({ children }) {
    const router = useRouter();
    const { authenticated, method } = useAuthContext();
    const [checked, setChecked] = useState(false);
    const check = useCallback(() => {
        if (!authenticated) {
            const searchParams = new URLSearchParams({
                returnTo: window.location.pathname,
            }).toString();
            const loginPath = loginPaths[method];
            const href = `${loginPath}?${searchParams}`;
            router.replace(href);
        }
        else {
            setChecked(true);
        }
    }, [authenticated, method, router]);
    useEffect(() => {
        check();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (!checked) {
        return null;
    }
    return _jsx(_Fragment, { children: children });
}
