import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { bgBlur } from 'src/theme/css';
import Logo from 'src/components/logo';
import { HEADER } from '../config-layout';
import HeaderShadow from './header-shadow';
import SettingsButton from './settings-button';
// ----------------------------------------------------------------------
export default function HeaderSimple() {
    const theme = useTheme();
    const offsetTop = useOffSetTop(HEADER.H_DESKTOP);
    return (_jsxs(AppBar, { children: [_jsxs(Toolbar, { sx: Object.assign({ justifyContent: 'space-between', height: {
                        xs: HEADER.H_MOBILE,
                        md: HEADER.H_DESKTOP,
                    }, transition: theme.transitions.create(['height'], {
                        easing: theme.transitions.easing.easeInOut,
                        duration: theme.transitions.duration.shorter,
                    }) }, (offsetTop && Object.assign(Object.assign({}, bgBlur({
                    color: theme.palette.background.default,
                })), { height: {
                        md: HEADER.H_DESKTOP_OFFSET,
                    } }))), children: [_jsx(Logo, {}), _jsxs(Stack, { direction: "row", alignItems: "center", spacing: 1, children: [_jsx(SettingsButton, {}), _jsx(Link, { color: "inherit", sx: { typography: 'subtitle2' }, children: "Need help?" })] })] }), offsetTop && _jsx(HeaderShadow, {})] }));
}
