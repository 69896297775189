import { loadingButtonClasses } from '@mui/lab/LoadingButton';
// ----------------------------------------------------------------------
export function loadingButton(theme) {
    return {
        MuiLoadingButton: {
            styleOverrides: {
                root: ({ ownerState }) => (Object.assign({}, (ownerState.variant === 'soft' && Object.assign({ [`& .${loadingButtonClasses.loadingIndicatorStart}`]: {
                        left: 10,
                    }, [`& .${loadingButtonClasses.loadingIndicatorEnd}`]: {
                        right: 14,
                    } }, (ownerState.size === 'small' && {
                    [`& .${loadingButtonClasses.loadingIndicatorStart}`]: {
                        left: 10,
                    },
                    [`& .${loadingButtonClasses.loadingIndicatorEnd}`]: {
                        right: 10,
                    },
                }))))),
            },
        },
    };
}
