import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useCallback } from 'react';
import { paths } from 'src/routes/paths';
import { useRouter, useSearchParams } from 'src/routes/hooks';
import { SplashScreen } from 'src/components/loading-screen';
import { useAuthContext } from '../hooks';
export default function GuestGuard({ children }) {
    const { loading } = useAuthContext();
    return _jsx(_Fragment, { children: loading ? _jsx(SplashScreen, {}) : _jsx(Container, { children: children }) });
}
// ----------------------------------------------------------------------
function Container({ children }) {
    const router = useRouter();
    const searchParams = useSearchParams();
    const returnTo = searchParams.get('returnTo') || paths.dashboard.root;
    const { authenticated } = useAuthContext();
    const check = useCallback(() => {
        if (authenticated) {
            router.replace(returnTo);
        }
    }, [authenticated, returnTo, router]);
    useEffect(() => {
        check();
    }, [check]);
    return _jsx(_Fragment, { children: children });
}
