var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import Box from '@mui/material/Box';
// ----------------------------------------------------------------------
export default function HeaderShadow(_a) {
    var { sx } = _a, other = __rest(_a, ["sx"]);
    return (_jsx(Box, Object.assign({ sx: Object.assign({ left: 0, right: 0, bottom: 0, m: 'auto', height: 24, zIndex: -1, opacity: 0.48, borderRadius: '50%', position: 'absolute', width: `calc(100% - 48px)`, boxShadow: (theme) => theme.customShadows.z8 }, sx) }, other)));
}
