var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
// ----------------------------------------------------------------------
export default function LoadingScreen(_a) {
    var { sx } = _a, other = __rest(_a, ["sx"]);
    return (_jsx(Box, Object.assign({ sx: Object.assign({ px: 5, width: 1, flexGrow: 1, minHeight: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }, sx) }, other, { children: _jsx(LinearProgress, { color: "inherit", sx: { width: 1, maxWidth: 360 } }) })));
}
