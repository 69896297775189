import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { paths } from 'src/routes/paths';
import SvgColor from 'src/components/svg-color';
// ----------------------------------------------------------------------
const icon = (name) => (_jsx(SvgColor, { src: `/assets/icons/navbar/${name}.svg`, sx: { width: 1, height: 1 } })
// OR
// <Iconify icon="fluent:mail-24-filled" />
// https://icon-sets.iconify.design/solar/
// https://www.streamlinehq.com/icons
);
const ICONS = {
    job: icon('ic_job'),
    blog: icon('ic_blog'),
    chat: icon('ic_chat'),
    mail: icon('ic_mail'),
    user: icon('ic_user'),
    file: icon('ic_file'),
    lock: icon('ic_lock'),
    tour: icon('ic_tour'),
    order: icon('ic_order'),
    label: icon('ic_label'),
    blank: icon('ic_blank'),
    kanban: icon('ic_kanban'),
    folder: icon('ic_folder'),
    banking: icon('ic_banking'),
    booking: icon('ic_booking'),
    invoice: icon('ic_invoice'),
    product: icon('ic_product'),
    calendar: icon('ic_calendar'),
    disabled: icon('ic_disabled'),
    external: icon('ic_external'),
    menuItem: icon('ic_menu_item'),
    ecommerce: icon('ic_ecommerce'),
    analytics: icon('ic_analytics'),
    dashboard: icon('ic_dashboard'),
};
// ----------------------------------------------------------------------
export function useNavData() {
    const data = useMemo(() => [
        // OVERVIEW
        // ----------------------------------------------------------------------
        {
            subheader: 'v0.9.0 Alpha',
            items: [
                { title: 'Dashboard', path: paths.dashboard.root, icon: ICONS.dashboard },
                // { title: 'Analytics', path: paths.dashboard.root, icon: ICONS.analytics },
                { title: 'Mock playground', path: paths.dashboard.mock, icon: ICONS.chat },
                { title: 'Interview Agent', path: paths.dashboard.interviews.index, icon: ICONS.calendar },
                { title: 'Resource manager', path: paths.dashboard.resources.index, icon: ICONS.file },
                { title: 'Roles', path: paths.dashboard.roles.index, icon: ICONS.job },
                // { title: 'Agents', path: paths.dashboard.group.six, icon: ICONS.ecommerce },
                // { title: 'Job Alerts', path: paths.dashboard.group.six, icon: ICONS.job },
            ],
        },
        // MANAGEMENT
        // ----------------------------------------------------------------------
        // {
        //   subheader: 'management',
        //   items: [
        //     {
        //       title: 'user',
        //       path: paths.dashboard.group.root,
        //       icon: ICONS.user,
        //       children: [
        //         { title: 'four', path: paths.dashboard.group.root },
        //         { title: 'five', path: paths.dashboard.group.five },
        //         { title: 'six', path: paths.dashboard.group.six },
        //       ],
        //     },
        //   ],
        // },
    ], []);
    return data;
}
