var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import Box from '@mui/material/Box';
const SvgColor = forwardRef((_a, ref) => {
    var { src, sx } = _a, other = __rest(_a, ["src", "sx"]);
    return (_jsx(Box, Object.assign({ component: "span", className: "svg-color", ref: ref, sx: Object.assign({ width: 24, height: 24, display: 'inline-block', bgcolor: 'currentColor', mask: `url(${src}) no-repeat center / contain`, WebkitMask: `url(${src}) no-repeat center / contain` }, sx) }, other)));
});
export default SvgColor;
