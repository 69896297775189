import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import { useMockedUser } from 'src/hooks/use-mocked-user';
import { bgBlur } from 'src/theme/css';
import Scrollbar from 'src/components/scrollbar';
import { NavSectionHorizontal } from 'src/components/nav-section';
import { HEADER } from '../config-layout';
import { useNavData } from './config-navigation';
import HeaderShadow from '../common/header-shadow';
// ----------------------------------------------------------------------
function NavHorizontal() {
    const theme = useTheme();
    const { user } = useMockedUser();
    const navData = useNavData();
    return (_jsxs(AppBar, { component: "div", sx: {
            top: HEADER.H_DESKTOP_OFFSET,
        }, children: [_jsx(Toolbar, { sx: Object.assign({}, bgBlur({
                    color: theme.palette.background.default,
                })), children: _jsx(Scrollbar, { sx: {
                        '& .simplebar-content': {
                            display: 'flex',
                        },
                    }, children: _jsx(NavSectionHorizontal, { data: navData, slotProps: {
                            currentRole: user === null || user === void 0 ? void 0 : user.role,
                        }, sx: Object.assign({}, theme.mixins.toolbar) }) }) }), _jsx(HeaderShadow, {})] }));
}
export default memo(NavHorizontal);
