import { paths } from 'src/routes/paths';
// API
// ----------------------------------------------------------------------
export const HOST_API = process.env.VITE_HOST_API;
export const ASSETS_API = process.env.VITE_ASSETS_API;
export const FIREBASE_API = {
    apiKey: process.env.VITE_FIREBASE_API_KEY,
    authDomain: process.env.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VITE_FIREBASE_APPID,
    measurementId: process.env.VITE_FIREBASE_MEASUREMENT_ID,
};
export const AMPLIFY_API = {
    userPoolId: process.env.VITE_AWS_AMPLIFY_USER_POOL_ID,
    userPoolWebClientId: process.env.VITE_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
    region: process.env.VITE_AWS_AMPLIFY_REGION,
};
export const AUTH0_API = {
    clientId: process.env.VITE_AUTH0_CLIENT_ID,
    domain: process.env.VITE_AUTH0_DOMAIN,
    callbackUrl: process.env.VITE_AUTH0_CALLBACK_URL,
};
export const SUPABASE_API = {
    url: process.env.VITE_SUPABASE_URL,
    key: process.env.VITE_SUPABASE_ANON_KEY,
};
export const MAPBOX_API = process.env.VITE_MAPBOX_API;
// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.root; // as '/dashboard'
