var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import { alpha, styled } from '@mui/material/styles';
import ListItemButton from '@mui/material/ListItemButton';
import { RouterLink } from 'src/routes/components';
import Iconify from '../../iconify';
// ----------------------------------------------------------------------
const NavItem = forwardRef((_a, ref) => {
    var { title, path, icon, info, disabled, caption, roles, 
    //
    open, depth, active, hasChild, externalLink, currentRole = 'admin' } = _a, other = __rest(_a, ["title", "path", "icon", "info", "disabled", "caption", "roles", "open", "depth", "active", "hasChild", "externalLink", "currentRole"]);
    const subItem = depth !== 1;
    const renderContent = (_jsxs(StyledNavItem, Object.assign({ ref: ref, disableGutters: true, open: open, depth: depth, active: active, disabled: disabled }, other, { children: [!subItem && icon && (_jsx(Box, { component: "span", className: "icon", children: icon })), subItem && icon ? (_jsx(Box, { component: "span", className: "icon", children: icon })) : (_jsx(Box, { component: "span", className: "sub-icon" })), title && (_jsxs(Box, { component: "span", sx: { flex: '1 1 auto', minWidth: 0 }, children: [_jsx(Box, { component: "span", className: "label", children: title }), caption && (_jsx(Tooltip, { title: caption, placement: "top-start", children: _jsx(Box, { component: "span", className: "caption", children: caption }) }))] })), info && (_jsx(Box, { component: "span", className: "info", children: info })), hasChild && (_jsx(Iconify, { width: 16, className: "arrow", icon: open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill' }))] })));
    // Hidden item by role
    if (roles && !roles.includes(`${currentRole}`)) {
        return null;
    }
    if (hasChild) {
        return renderContent;
    }
    if (externalLink)
        return (_jsx(Link, { href: path, target: "_blank", rel: "noopener", color: "inherit", underline: "none", sx: Object.assign({}, (disabled && {
                cursor: 'default',
            })), children: renderContent }));
    return (_jsx(Link, { component: RouterLink, href: path, color: "inherit", underline: "none", sx: Object.assign({}, (disabled && {
            cursor: 'default',
        })), children: renderContent }));
});
export default NavItem;
// ----------------------------------------------------------------------
const StyledNavItem = styled(ListItemButton, {
    shouldForwardProp: (prop) => prop !== 'active',
})(({ active, open, depth, theme }) => {
    const subItem = depth !== 1;
    const opened = open && !active;
    const deepSubItem = Number(depth) > 2;
    const noWrapStyles = {
        width: '100%',
        maxWidth: '100%',
        display: 'block',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    };
    const baseStyles = {
        item: {
            marginBottom: 4,
            borderRadius: 8,
            color: theme.palette.text.secondary,
            padding: theme.spacing(0.5, 1, 0.5, 1.5),
        },
        icon: {
            width: 24,
            height: 24,
            flexShrink: 0,
            marginRight: theme.spacing(2),
        },
        label: Object.assign(Object.assign(Object.assign({}, noWrapStyles), theme.typography.body2), { textTransform: 'capitalize', fontWeight: theme.typography[active ? 'fontWeightSemiBold' : 'fontWeightMedium'] }),
        caption: Object.assign(Object.assign(Object.assign({}, noWrapStyles), theme.typography.caption), { color: theme.palette.text.disabled }),
        info: {
            display: 'inline-flex',
            marginLeft: theme.spacing(0.75),
        },
        arrow: {
            flexShrink: 0,
            marginLeft: theme.spacing(0.75),
        },
    };
    return Object.assign(Object.assign(Object.assign({}, (!subItem && Object.assign(Object.assign(Object.assign(Object.assign({}, baseStyles.item), { minHeight: 44, '& .icon': Object.assign({}, baseStyles.icon), '& .sub-icon': {
            display: 'none',
        }, '& .label': Object.assign({}, baseStyles.label), '& .caption': Object.assign({}, baseStyles.caption), '& .info': Object.assign({}, baseStyles.info), '& .arrow': Object.assign({}, baseStyles.arrow) }), (active && {
        color: theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.primary.light,
        backgroundColor: alpha(theme.palette.primary.main, 0.08),
        '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.16),
        },
    })), (opened && {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.action.hover,
    })))), (subItem && Object.assign(Object.assign(Object.assign({}, baseStyles.item), { minHeight: 36, '& .icon': Object.assign({}, baseStyles.icon), '& .sub-icon': Object.assign(Object.assign({}, baseStyles.icon), { display: 'flex', alignItems: 'center', justifyContent: 'center', '&:before': Object.assign({ content: '""', width: 4, height: 4, borderRadius: '50%', backgroundColor: theme.palette.text.disabled, transition: theme.transitions.create(['transform'], {
                    duration: theme.transitions.duration.shorter,
                }) }, (active && {
                transform: 'scale(2)',
                backgroundColor: theme.palette.primary.main,
            })) }), '& .label': Object.assign({}, baseStyles.label), '& .caption': Object.assign({}, baseStyles.caption), '& .info': Object.assign({}, baseStyles.info), '& .arrow': Object.assign({}, baseStyles.arrow) }), (active && {
        color: theme.palette.text.primary,
    })))), (deepSubItem && {
        paddingLeft: `${theme.spacing(Number(depth))} !important`,
    }));
});
