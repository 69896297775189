import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import { alpha } from '@mui/material/styles';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Label from 'src/components/label';
export default function ResultItem({ title, path, groupLabel, onClickItem }) {
    return (_jsxs(ListItemButton, { onClick: onClickItem, sx: {
            borderWidth: 1,
            borderStyle: 'dashed',
            borderColor: 'transparent',
            borderBottomColor: (theme) => theme.palette.divider,
            '&:hover': {
                borderRadius: 1,
                borderColor: (theme) => theme.palette.primary.main,
                backgroundColor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
            },
        }, children: [_jsx(ListItemText, { primaryTypographyProps: {
                    typography: 'subtitle2',
                    sx: { textTransform: 'capitalize' },
                }, secondaryTypographyProps: { typography: 'caption' }, primary: title.map((part, index) => (_jsx(Box, { component: "span", sx: {
                        color: part.highlight ? 'primary.main' : 'text.primary',
                    }, children: part.text }, index))), secondary: path.map((part, index) => (_jsx(Box, { component: "span", sx: {
                        color: part.highlight ? 'primary.main' : 'text.secondary',
                    }, children: part.text }, index))) }), groupLabel && _jsx(Label, { color: "info", children: groupLabel })] }));
}
