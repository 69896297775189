import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ButtonBase from '@mui/material/ButtonBase';
import { alpha, useTheme } from '@mui/material/styles';
export default function LayoutOptions({ options, value, onChange }) {
    const theme = useTheme();
    const renderNav = (option, selected) => {
        const background = `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`;
        const baseStyles = {
            flexShrink: 0,
            borderRadius: 0.5,
            bgcolor: 'grey.500',
        };
        const circle = (_jsx(Box, { sx: Object.assign(Object.assign(Object.assign({}, baseStyles), { width: 8, height: 8 }), (selected && { background })) }));
        const primaryItem = (_jsx(Box, { sx: Object.assign(Object.assign(Object.assign(Object.assign({}, baseStyles), { width: 1, height: 3, opacity: 0.48 }), (option === 'horizontal' && {
                width: 12,
            })), (selected && { background })) }));
        const secondaryItem = (_jsx(Box, { sx: Object.assign(Object.assign(Object.assign(Object.assign({}, baseStyles), { width: 1, height: 3, maxWidth: 12, opacity: 0.24 }), (option === 'horizontal' && {
                width: 8,
            })), (selected && { background })) }));
        return (_jsxs(Stack, { spacing: 0.5, flexShrink: 0, direction: option === 'horizontal' ? 'row' : 'column', sx: Object.assign(Object.assign({ p: 0.5, width: 28, height: 1, borderRight: `solid 1px ${alpha(theme.palette.grey[500], 0.08)}` }, (option === 'mini' && {
                width: 16,
            })), (option === 'horizontal' && {
                width: 1,
                height: 16,
                alignItems: 'center',
                borderRight: 'unset',
                borderBottom: `solid 1px ${alpha(theme.palette.grey[500], 0.08)}`,
            })), children: [circle, primaryItem, secondaryItem] }));
    };
    const renderContent = (selected) => (_jsx(Box, { sx: { p: 0.5, flexGrow: 1, height: 1, width: 1 }, children: _jsx(Box, { sx: Object.assign({ width: 1, height: 1, opacity: 0.08, borderRadius: 0.5, bgcolor: 'grey.500' }, (selected && {
                opacity: 0.24,
                background: `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
            })) }) }));
    return (_jsx(Stack, { direction: "row", spacing: 2, children: options.map((option) => {
            const selected = value === option;
            return (_jsxs(ButtonBase, { onClick: () => onChange(option), sx: Object.assign(Object.assign({ p: 0, width: 1, height: 56, borderRadius: 1, border: `solid 1px ${alpha(theme.palette.grey[500], 0.08)}` }, (selected && {
                    bgcolor: 'background.paper',
                    boxShadow: `-24px 8px 24px -4px ${alpha(theme.palette.mode === 'light'
                        ? theme.palette.grey[500]
                        : theme.palette.common.black, 0.08)}`,
                })), (option === 'horizontal' && {
                    flexDirection: 'column',
                })), children: [renderNav(option, selected), renderContent(selected)] }, option));
        }) }));
}
