var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { fetchAuthSession } from '@aws-amplify/auth';
import axios from 'src/utils/axios';
// ----------------------------------------------------------------------
function jwtDecode(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window
        .atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join(''));
    return JSON.parse(jsonPayload);
}
// ----------------------------------------------------------------------
export const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false;
    }
    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;
    return decoded.exp > currentTime;
};
// ----------------------------------------------------------------------
export const tokenExpired = (exp) => {
    // eslint-disable-next-line prefer-const
    let expiredTimer;
    const currentTime = Date.now();
    // Test token expires after 10s
    // const timeLeft = currentTime + 10000 - currentTime; // ~10s
    const timeLeft = exp * 1000 - currentTime;
    console.log('timeLeft', timeLeft);
    clearTimeout(expiredTimer);
    expiredTimer = setTimeout(() => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const session = yield fetchAuthSession();
        const accessToken = (_a = session.tokens) === null || _a === void 0 ? void 0 : _a.accessToken;
        if (!accessToken) {
            return;
        }
        sessionStorage.setItem('accessToken', accessToken.toString() || '');
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    }), timeLeft);
};
// ----------------------------------------------------------------------
export const setSession = (accessToken) => {
    if (accessToken) {
        sessionStorage.setItem('accessToken', accessToken);
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        // This function below will handle when token is expired
        const { exp } = jwtDecode(accessToken); // ~3 days by minimals server
        tokenExpired(exp);
    }
    else {
        sessionStorage.removeItem('accessToken');
        delete axios.defaults.headers.common.Authorization;
    }
};
