var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import { HOST_API } from '../config-global';
// ----------------------------------------------------------------------
const axiosInstance = axios.create({ baseURL: HOST_API });
axiosInstance.interceptors.response.use((res) => res, (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong'));
export default axiosInstance;
// ----------------------------------------------------------------------
export const fetcher = (args) => __awaiter(void 0, void 0, void 0, function* () {
    const [url, config] = Array.isArray(args) ? args : [args];
    const res = yield axiosInstance.get(url, Object.assign({}, config));
    return res.data;
});
// ----------------------------------------------------------------------
export const endpoints = {
    chat: {
        conversation: '/api/chat/conversation',
        conversations: '/api/chat/conversations',
        markAsSeen: '/api/chat/conversation/mark-as-seen',
        messages: '/api/chat/messages/new',
        participants: '/api/chat/participants',
        contacts: '/api/chat/contacts',
        search: '/api/chat/search',
    },
    kanban: '/api/kanban',
    calendar: '/api/calendar',
    auth: {
        me: 'api/account/my-account',
        login: '/api/auth/login',
        register: '/api/auth/register',
    },
    mail: {
        list: '/api/mail/list',
        details: '/api/mail/details',
        labels: '/api/mail/labels',
    },
    post: {
        list: '/api/post/list',
        details: '/api/post/details',
        latest: '/api/post/latest',
        search: '/api/post/search',
    },
    product: {
        list: '/api/product/list',
        details: '/api/product/details',
        search: '/api/product/search',
    },
};
